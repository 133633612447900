import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Container from "./Container";
import ReusableButton from "./ReusableButton";

// AllBlogs component displays a list of blog articles
const AllBlogs = ({ blogArticles = [], onArticleClick, loadMoreArticles, showLoadMoreButton }) => {
  const theme = useTheme();

  return (
    <Container>
      {/* Header section */}
      <Box
        sx={{
          mb: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: 1,
        }}
      >
        {/* Title */}
        <Typography variant="description" component="p" color={theme.palette.text.primary2}>
          Blogs and News
        </Typography>
        {/* Subtitle */}
        <Typography
          variant="h2"
          sx={{
            mt: 2,
          }}
        >
          All Blog Posts
        </Typography>
      </Box>
      {/* Blog articles grid */}
      <Grid container spacing={3} rowSpacing={10}>
        {/* Map through each article and display it */}
        {blogArticles.map((article, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            {/* Article image */}
            <Box
              component={"img"}
              src={article?.imageUrl?.asset?.url || article?.imageUrl}
              alt={"Blog Image"}
              sx={{ borderRadius: 2, width: 1 }}
              loading="lazy"
            />
            {/* Article title */}
            <Typography
              variant="title1"
              component={"h5"}
              onClick={() => onArticleClick(article?.slug?.current || article?.slug)}
              color={theme.palette.text.header2}
              sx={{
                cursor: "pointer",
                width: { xs: 1, md: 0.8 },
                mt: 2,
                "&:hover": {
                  color: "#0085FF",
                  textDecoration: "underline",
                },
              }}
            >
              {article.title}
            </Typography>
          </Grid>
        ))}
        ;
      </Grid>
      {/* Load more button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
        }}
      >
        {showLoadMoreButton ? (
          <ReusableButton variant="secondary" title="Load More" onClick={loadMoreArticles} />
        ) : (
          <></>
        )}
      </Box>
    </Container>
  );
};

// PropTypes for the AllBlogs component
AllBlogs.propTypes = {
  blogArticles: PropTypes.array, // Array of blog articles
  onArticleClick: PropTypes.func, // Function to handle article click
  loadMoreArticles: PropTypes.func, // Function to load more articles
  showLoadMoreButton: PropTypes.bool, // Bool to display or hide button
};

export default AllBlogs;
