import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Container from "./Container";
import GradientImg from "images/five-card-layout/Gradient.svg";
import OrangeHighLightImg from "images/five-card-layout/OrangeShape.svg";
import bgUrl from "images/hero-header/staticBackground.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const HighlightBlogContainer = ({ highlightArticles = [], onArticleClick }) => {
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  // Sorting highlight articles by date
  highlightArticles.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  // Formatting the date of each article
  highlightArticles.forEach((article) => {
    let dateObj = new Date(article.date);
    let day = dateObj.getDate();
    let month = dateObj.toLocaleString("default", { month: "short" });
    let year = dateObj.getFullYear();
    article.date = `${day} ${month} ${year}`;
  });

  // Styles for the component
  const styles = {
    tagStyles: {
      bgcolor: "#2C85FF",
      color: theme.palette.text.light,
      px: 2,
      borderRadius: 10,
    },
    dateStyles: {
      color: theme.palette.text.description2,
    },
    titleStyles: {
      "&:hover": {
        color: theme.palette.text.primary2,
        textDecoration: "underline",
      },
    },
  };

  // Rendering the HighlightBlogContainer component
  return (
    <Box sx={{ backgroundImage: `url(${bgUrl})` }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                height: "100%",
                borderRadius: 4,
                padding: 3,
                color: "white",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                cursor: "pointer",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "#151B35",
                backgroundImage: `url(${GradientImg})`,
              }}
              loading="lazy"
              onClick={() => onArticleClick(highlightArticles[0]?.slug?.current)}
            >
              <Box
                component={"img"}
                src={OrangeHighLightImg}
                sx={{ position: "absolute", top: "83%", left: "5%", display: { xs: "none", md: "block" } }}
                alt="Appnigma AI no ETL constraint icon"
                loading="lazy"
              ></Box>
              {/* Displaying the first highlight article */}
              <Box
                component={"img"}
                src={highlightArticles[0]?.imageUrl?.asset?.url}
                alt={"Blog Image"}
                sx={{ borderRadius: 2 }}
                loading="lazy"
              />
              <Box display={"flex"} gap={2}>
                <Typography sx={styles.tagStyles}>{highlightArticles[0]?.tag}</Typography>
                <Typography variant="body1" sx={styles.dateStyles}>
                  {highlightArticles[0]?.date}
                </Typography>
              </Box>
              <Typography variant="title1" color={theme.palette.text.light} sx={styles.titleStyles}>
                {highlightArticles[0]?.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} display={"flex"} flexDirection={"column"} gap={3}>
            {/* Displaying the rest of the highlight articles */}
            {highlightArticles.slice(1, 4).map((article, index) => (
              <Box
                position={"relative"}
                zIndex={1}
                key={index}
                sx={{
                  display: "flex",
                  borderRadius: 4,
                  border: "1px solid #FFA564",
                  padding: 3,
                  cursor: "pointer",
                  flexDirection: isSm ? "row" : "column",
                }}
                onClick={() => onArticleClick(article?.slug?.current)}
              >
                <Box
                  component={"img"}
                  src={article?.imageUrl?.asset?.url}
                  alt={"decorative"}
                  sx={{ borderRadius: 2, marginBottom: isSm ? "0" : "20px" }}
                  width={isSm ? 0.25 :1}
                  loading="lazy"
                />
                <Box ml={3}>
                  <Box display={"flex"} gap={2}>
                    <Typography sx={styles.tagStyles}>{article?.tag}</Typography>
                    <Typography variant="body1" sx={styles.dateStyles}>
                      {article.date}
                    </Typography>
                  </Box>
                  <Typography
                    variant="title1"
                    color={theme.palette.text.header2}
                    sx={{ ...styles.titleStyles, mt: 2 }}
                    component={"h5"}
                  >
                    {article.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

// Exporting HighlightBlogContainer component
export default HighlightBlogContainer;
