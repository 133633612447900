import React from "react";
import Blogs from "views/Blogs";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

const BlogsPage = ({ data, error }) => {
  if (error) {
    return <div>Error fetching data...</div>;
  }
  const blogArticles = data.allSanityArticle.nodes;

  return (
    <>
      <Helmet>
        <title>Appnigma AI</title>
        <meta name="description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta property="og:title" content="Appnigma AI" />
        <meta property="og:description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta property="og:image" content="src/images/appnigma_full_logo.webp" />
        <meta property="og:url" content={`https://www.appnigma.ai/`} />
        <meta name="twitter:title" content="Appnigma AI" />
        <meta name="twitter:description" content="Generate code to bridge Salesforce & other apps, powered by AI" />
        <meta name="twitter:image" content="src/images/appnigma_full_logo.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Blogs blogArticles={blogArticles} />
    </>
  );
};

// graphql query to generate page with data
export const query = graphql`
  query MyQuery {
    allSanityArticle(sort: { fields: _createdAt, order: DESC }, filter: { articleType: { eq: "BlogPost" } }) {
      nodes {
        title: headline
        createdAt: _createdAt
        date: _createdAt
        description: _rawDescription
        slug: slug {
          current
        }
        imageUrl: image {
          asset {
            url
          }
        }
        tag: tags
      }
    }
  }
`;

export default BlogsPage;
